<template lang="pug">
.share
	Menu
		template(#title)
			.flex.items-center
				| Share of Search

	template(v-if="isMounted")
		ContainerForData.mt-12(
			:isLoading="isLoading"
			width="100%"
			titleVisible
			titleType="content"
		)
			template(#header-left)
				.pl-8.pt-8.flex.items-center
					h2.mr-8.section-title Прохождение таргета
					Tag(:label="target" type="green")

			template(#header-right)
				MultiSelectWithSearch(
					name="Поисковые запросы"
					:items="selectItems"
					is-tree
					has-select-all
					has-search
					search-placeholder="Поиск запроса"
					tooltip-placement="bottom-end"
					@select-all="selectAllItems(true)"
					@clear-all="selectAllItems(false)"
					@select-item="isSelectDirty = true"
					@hide="fetchBySelectedItems()"
				)

			template(#data)
				template(v-if="resp.stores && resp.terms")
					Table(v-bind="searchOptions")
						template(#searchCaption)
							.flex.items-center.first-column-header
								span Запрос
								span.ml-auto Средний % выполн. таргета по запросу

						template(#storeCaption="{title, addon}")
							.flex.items-center
								.table-title.text-left.small {{ title }}

								Tooltip
									template(#reference)
										.header-percent(
											v-if="addon"
											:class="{'--green': parseInt(addon) >= 15, '--red': parseInt(addon) < 15}"
										)
											| {{ addon }}

									.percent-tooltip
										| Cредний % выполнения таргета на маркете: {{ title }}

						template(#name="{item, index, col}")
							.flex.items-center
								.table-name-item {{ item.query }}
								.ml-auto.mr-8 {{ getAverage(item) }}%
						template(#store="{item, index, value, col}")
							Tooltip
								template(#reference)
									.color-container.flex.items-center.justify-center.flex-col(
										@click="getSearchId(item, col)"
										:class="getColor(getStoreValue(item, col))"
									)
										template(v-if="getStoreValue(item, col) !== null") 
											div {{ getStoreValue(item, col) }}%
										template(v-else)
											div No data

								.color-container-tooltip
									| Процент выполнения таргета по запросу: “{{ item.query }}” на маркете: “{{ col.title }}”
				template(v-else)
					| Чтобы данные отобразились в дашборде, выберите значения в фильтрах и нажмите "Apply filter”

			template(
				v-if="hasPagination"
				#pagination
			)
				.element-container__content.flex.items-center.justify-center
					pagination(
						:total="resp.count"
						:current="current"
						:limit="limit"
						@change-page="current = $event"
					)

		div(ref="table")
			ContainerForData.mt-24(
				v-if="terms"
				width="100%"
				:titleVisible="false"
				:isLoading="isLoading"
			)
				template(#data)
					template(v-if="tableOptions.data.length")
						Table.brands__table(v-bind="tableOptions")
							template(#position="{item, i, value}")
								.table-rank
									.position.flex.justify-center {{ value.position }}
									.percent-rank.flex.justify-center(v-if="value.rank") {{ value.rank }}%
							template(#image="{item, i, value}")
								.table-image(:style="{backgroundImage: `url(${value})`}")
							template(#product="{item, i, value}")
								a(
									v-if="item.show_url"
									:href="item.sku_url"
									target="_blank"
								) {{ value.name }}
								.name(v-else) {{ value.name }}
								div
									strong RPC
									span.ml-8 {{ value.rpc }}
								div
									strong UPC
									span.ml-8 {{ value.upc }}
							template(#brand="{ value }")
								.circle {{ value }}
							template(#manufacturer="{ value }")
								div {{ value }}
					template(v-else)
						pre Data not found

	.empty-filter(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
import { renameYandexByName } from '@/helpers/RenameYandexPokupki'
import Menu from '@/components/Menu/Menu.vue'
import Tooltip from '@/components/Elements/Tooltip.vue'
import HelpCircle from 'vue-material-design-icons/HelpCircle.vue'
import CalendarExport from 'vue-material-design-icons/CalendarExport.vue'
import FlagVariant from 'vue-material-design-icons/FlagVariant.vue'
import Badge from '@/components/Chart/Badge'
import ContainerForData from '@/components/Nestle/ContainerForData'
import Table from '@/components/Table/Table'
import Tag from '@/components/Tag/Tag'
import Pagination from "@/components/Pagination/Pagination.vue"
import MultiSelectWithSearch from '@/components/Elements/MultiSelect/MultiSelectWithSearch.vue'

export default {
	name: 'ShareOfSearch',
	components: {
		Table,
		ContainerForData,
		Badge,
		Tooltip,
		CalendarExport,
		HelpCircle,
		FlagVariant,
		Menu,
		Tag,
		Pagination,
		MultiSelectWithSearch,
	},
	props: {
    excludeSku: {
      type: String,
      default: '',
    },
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
    subBrands: {
      type: String,
      default: '',
    },
		regions: {
			type: String,
			default: '',
		},
    seasonProducts: {
      type: String,
      default: '',
    },
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},

	},
	data() {
		return {
			searchTerm: 1,
			list: [],
			target: '15%',
			resp: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: true,
			isMounted: false,
			selectedSearchId: null,
			terms: null,

			selectItems: [],
			isSelectDirty: false,
		}
	},
	computed:{
		selectedItems() {
			return this.selectItems.filter((item) => item.checked);
		},

		hasPagination() {
			if (!this.resp?.count) return
			return this.resp.count / this.limit > 1
		},
		summaryCaptionTable() {
			return [
				{
					title: 'Search Term',
					slotCaption: 'searchCaption',
					width: 300,
					slot: 'name',
					cellClass: 'justify-center',
				}
			]
		},
		storesCaptionTable() {
			return this.resp.stores.map((item) => {
				return {
					id: item.storeId,
					title: item.store.name.replaceAll('_', ' '),
					slotCaption: 'storeCaption',
					width: 180,
					slot: 'store',
					alignCenter: true,
					addon: `${item.averagePercentage}%`,
					value: (item) => {
						return item.values
					}
				}
			})
		},
		searchColumns() {
			return this.summaryCaptionTable.concat(this.storesCaptionTable)
		},
		searchOptions() {
			return {
				sort: {field: 'name', order: 'desc'},
				columns: this.searchColumns,
				data: this.searchData,
				classes: {
					absolute: true,
				}
			}
		},
		searchData() {
			return this.resp.terms
		},

		tableColumns() {
			return [
				{
					title: 'Rank',
					slot: 'position',
					width: 80,
					cellClass: 'justify-center',
					value: ({ position, rank }) => ({ position, rank }),
				},
				{
					title: 'Image',
					slot: 'image',
					width: 80,
					cellClass: 'justify-center',
					value: ({ image }) => image || '123.jpg',
				},
				{
					title: 'Product Name',
					slot: 'product',
					width: 280,
					cellClass: 'justify-center',
					value: ({ name, rpc, upc }) => ({ name,	upc, rpc })
				},
				{
					title: 'Brand',
					width: 180,
					slot: 'brand',
					cellClass: 'justify-center',
					value: ({ brand }) => brand,
				},
				{
					title: 'Manufacturer',
					width: 80,
					slot: 'manufacturer',
					cellClass: 'justify-center',
					value: ({ manufacturer }) => manufacturer,
				},
			]
		},
		tableOptions() {
			return {
				sort: {field: 'name', order: 'desc'},
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
		tableData() {
			return this.terms
		},

	},
	methods: {
		selectAllItems(value) {
			this.selectItems.forEach((item) => {
				item.checked = value;
			})

			this.isSelectDirty = true;
		},

		async fetchBySelectedItems() {
			if (!this.isSelectDirty) return;
			this.offset = 0;
			this.current = 1;
			this.isSelectDirty = false;
			await this.fetch(false);
		},

		async fetch(shouldSetSelectItems = true) {
			this.isMounted = true

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
        subBrands: this.subBrands,
				regions: this.regions,
        excludeSku: this.excludeSku,
        seasonProducts: this.seasonProducts,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
				searchQueryIds: this.selectedItems.map((item) => item.id).join(','),
			};

			this.isLoading = true
			try {
				const result = await this.$api.nestle.getSearch(params)
				const renamedItems = renameYandexByName(result)
				this.resp = renamedItems

				if (shouldSetSelectItems) {
					this.selectItems = this.getSelectItems(result.queries);
				}
			} catch (error) {
				// ...
			} finally {
				this.isLoading = false
			}
		},

		getSelectItems(items) {
			this.isSelectDirty = false;
			if (!items || !Array.isArray(items)) return [];

			return items.map((item) => {
				return {
					id: item.id,
					parentId: item.groupId,
					parentName: item.groupName,
					name: item.name,
					checked: false,
					filterId: item.groupId,
				}
			})
		},

		async fetchTermById(id) {
			this.isMounted = true

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
        seasonProducts: this.seasonProducts,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
				store: id,
				searchId: this.selectedSearchId,
			};

			this.isLoading = true
			try {
				const result = await this.$api.nestle.getSearchTerm(params)
				this.terms = result?.productList
			} catch (error) {
				// ...
			} finally {
				this.isLoading = false
				this.scrollToTable()
			}
		},
		getColor(number) {
			if (number === null) {
				return '--disabled'
			}
			if (number < 6.99) {
				return '--red'
			}
			if (number < 14.99) {
				return '--yellow'
			}

			return '--green'
		},
		getAverage(item) {
			return (item.values.reduce((sum, el) => sum + el.percentage, 0) / item.values.length).toFixed(1)
		},
		getStoreValue(item, col) {
			const itemVal = item.values.find((el) => el.storeId === col.id)
			return !itemVal.isActive ? null : itemVal.percentage
		},
		scrollToTable() {
			const table = this.$refs.table
			const delay = 50
			setTimeout(() => {
				table.scrollIntoView({ behavior: 'smooth' })
			}, delay)
		},
		getSearchId(item, col) {
			this.selectedSearchId = item.id

			if (this.getStoreValue(item, col) !== null) {
				this.fetchTermById(col.id)
			}
		},
	},
	watch: {
		needUpdateResult: {
			async handler() {
				this.offset = 0
				this.current = 1
				this.selectAllItems(false);
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch(false)
			},
		},
	},
}
</script>

<style scoped lang="scss">
::v-deep.c{
	font-size: 30px;
}
.share {
	max-width: 1280px;
	margin: 0 auto;
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;

		::v-deep.control__input{
			color: color(white);
			font-weight: 400;
		}
	}
}
h1{
	color: color(gray-700);
}
.header{
	margin-bottom: 32px;
	&-right{
		margin-left: auto;
	}
	&-button {
		background-color: #fff;
		padding: 10px 5px;
		border-radius: 3px;
		color: color(gray-700);
		border: 1px color(gray-400) solid;
		&:not(:last-child){
			margin-right: 8px;
		}
		&:focus{
			outline: none;
		}
	}
}
.icon{
	color: color(gray-500);
	position: absolute;
	margin-left: 8px;
	margin-top: -6px;
	::v-deep.material-design-icon__svg {
		width: 15px;
		height: 15px;
	}
}
.icon-button{
	color: color(gray-700);
	::v-deep.material-design-icon__svg {
		width: 15px;
		height: 15px;
	}

	margin-right: 8px;
}
::v-deep.control__input{
	padding: 10px 5px;
	border: none;
	width: 80px;
	font-weight: 800;
	color: color(gray-700);
	cursor: pointer;

	&:focus{
		outline: none;
	}
}

select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}

.section-title {
	font-family: 'OpenSans';
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
}

.table-name-item {
	color: #000000;
}

.first-column-header {
	margin-left: -7px;
	margin-right: -30px;
	font-family: 'OpenSans';
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #424242;
}

.table-title{
	font-size: 24px;

	&.text-center {
		text-align: center;
	}
	&.small {
		font-size: 18px;
	}
}
.header-percent{
	text-align: center;
	font-weight: 700;
    position: absolute;
    right: 3px;
    bottom: 3px;

	&.--green {
		color: color(green);
	}
	&.--red{
		color: color(red);
	}
}
.table-image {
    width: 75px;
    height: 75px;
    background-size: contain;
    filter: inset(1);
    background-repeat: no-repeat;
    background-position: center center;
}
.position {
	font-weight: bold;
}
.table-rank {
	width: 15%;
}
.percent-rank {
	font-size: 12px;
	word-break: keep-all;
	white-space: nowrap;
  width: fit-content;
}
.flag-icon{
	color: color(orange-light);
}
.color-container{
	padding: 10px;
	color: white;
	border-radius: 3px;
	height: 40px;
	font-size: 12px;

	&.--red{
		background-color: color(red);
	}
	&.--yellow{
		background-color: color(orange-light);
	}
	&.--green{
		background-color: color(green);
	}
	&.--disabled {
		background-color: #dbdce0;
		color: #757575;
		cursor: not-allowed;
	}
}

.color-container-tooltip {
	word-break: keep-all;
}
::v-deep.td {
	padding: 2px;
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>
